<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div style="width:130px;">
                        <el-input v-model="where.xingm" placeholder="请输入姓名或手机号码"></el-input>
                    </div>
                    <div style="width:140px;">
                        <el-select v-model="where.shenhzt" placeholder="选择审核状态">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="审核通过" value="1"></el-option>
                            <el-option label="审核拒绝" value="2"></el-option>
                            <el-option label="审核中" value="3"></el-option>
                            <el-option label="待审核" value="4"></el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange"
                                        range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期"></el-date-picker>
                    </div>

                    <div>
                        <el-button icon="el-icon-search" @click="get_store_list()">条件筛选</el-button>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div class="admin_main_block_right" style="width:600px;">
                    <div>
                        <el-button type="primary" icon="el-icon-caret-left" @click="pilshenh_click(select_id)">批量审核</el-button>
                        <!--<el-button type="primary" icon="el-icon-caret-left" @click="pilguanf_click(select_id)">批量官方推荐</el-button>
                        <el-button type="primary" icon="el-icon-caret-left" @click="pilshangxj_click(select_id)">批量上下架</el-button>
                        <el-button type="primary" icon="el-icon-caret-left" @click="pilrem_click(select_id)">批量热门推荐</el-button>
                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>-->
                    </div>
                </div>
            </div>
            <!--<div class="admin_main_block_top">
                <div class="admin_main_block_right" style="width:600px;">
                    <div>
                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                    </div>
                </div>
            </div>-->


            <div class="admin_table_main">
                <el-table :data="info" @selection-change="handleSelectionChange">
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="id" label="#" fixed="left" width="60px"></el-table-column>


                    <el-table-column prop="xingm" label="姓名"></el-table-column>
                    <el-table-column prop="shoujhm" label="手机号码"></el-table-column>

                    <el-table-column prop="suozcs" label="所在城市"></el-table-column>
                    <el-table-column prop="xiangxdz" label="详细地址"></el-table-column>

                    <el-table-column prop="miaos" label="描述"></el-table-column>


                    <el-table-column label="身份证正面" width="80px">
                        <template slot-scope="scope">
                            <el-popover placement="left" trigger="click" width="600">
                                <img :src="scope.row.shenfzzm||require('@/assets/default_pic.png')" width="100%"/>
                                <img
                                        slot="reference"
                                        :src="scope.row.shenfzzm||require('@/assets/default_pic.png')"
                                        :alt="scope.row.shenfzzm"
                                        style="max-height: 60px;max-width: 60px; padding: 5px"
                                />
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="身份证反面" width="180px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="600">
                                        <img :src="scope.row.shenfzfm||require('@/assets/default_pic.png')"
                                             width="100%"/>
                                        <img
                                                slot="reference"
                                                :src="scope.row.shenfzfm||require('@/assets/default_pic.png')"
                                                :alt="scope.row.shenfzfm"
                                                style="max-height: 60px;max-width: 60px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>

                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column label="房产证" width="180px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="600">
                                        <img :src="scope.row.fangcz||require('@/assets/default_pic.png')"
                                             width="100%"/>
                                        <img
                                                slot="reference"
                                                :src="scope.row.fangcz||require('@/assets/default_pic.png')"
                                                :alt="scope.row.fangcz"
                                                style="max-height: 60px;max-width: 60px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column label="合同凭据" width="180px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="600">
                                        <img :src="scope.row.hetpj||require('@/assets/default_pic.png')"
                                             width="100%"/>
                                        <img
                                                slot="reference"
                                                :src="scope.row.hetpj||require('@/assets/default_pic.png')"
                                                :alt="scope.row.hetpj"
                                                style="max-height: 60px;max-width: 60px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>
                            </dl>
                        </template>
                    </el-table-column>


                    <el-table-column label="审核状态" width="100px">
                        <template slot-scope="scope">
                            <el-tag type="gray" v-if="scope.row.shenhzt==1">审核通过 </el-tag>
                            <el-tag type="success" v-if="scope.row.shenhzt==2">审核拒绝</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.shenhzt==3">审核中</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.shenhzt==4">待审核</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" width="120px">
                        <template slot-scope="scope">
                            <div>{{scope.row.login_time|formatDate}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="160px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="verify_click(scope.row.id)">审核</el-button>
                            <!--<el-button icon="el-icon-tickets" @click="read_store_info(scope.row)">查看</el-button>-->
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total"
                                   :total="total_data" :page-size="page_size"
                                   :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                list: [],
                total_data: 0, // 总条数
                page_size: 20,
                current_page: 1,
                select_id: '',
                dialogVisible: false,
                info: [],
                store_verify: 0,
                where: {
                    times: [],
                },
            };
        },
        watch: {},
        computed: {},
        methods: {
            handleSelectionChange: function (e) {
                let ids = [];
                e.forEach(v => {
                    ids.push(v.F_IN_ID);
                });
                this.select_id = ids.join(',');
            },
            get_store_list: function () {
                let where = {};
                where.page = this.current_page;
                where.params = this.where;
                this.$get(this.$api.getTgBizlbList, where).then(res => {
                    console.log('---------------------')
                    console.log(res.data.data)
                    console.log('---------------------')
                    this.page_size = res.data.per_page;
                    this.total_data = res.data.total;
                    this.current_page = res.data.current_page;
                    this.list = res.data;
                    this.info = res.data.data;


                })
            },
            read_store_info(e) {
                this.info = e;
                this.store_verify = this.info.store_verify;
                this.dialogVisible = true;
            },
            read_pic(e) {
                return [e];
            },
            update_store: function () {
                if (this.info.store_status == 0 && this.info.store_close_info == '') {
                    return this.$message.error('请填写关闭店铺的原因！');
                }
                this.info.store_verify = this.store_verify;
                this.$post(this.$api.StorePass, this.info).then(res => {
                    console.log(res)
                    this.$message.success('修改成功');
                    this.dialogVisible = false;
                    this.get_store_list();
                });
            },
            // 分页改变
            current_change: function (e) {
                this.current_page = e;
                this.get_store_list();
            },

            // 批量热门推荐
            pilrem_click: function (F_IN_ID) {
                this.$confirm('此操作将控制是否通过推荐, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlplbremStatus, {F_IN_ID: F_IN_ID, status: 1}).then(() => {
                        this.$message.success('成功推荐');
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlplbremStatus, {F_IN_ID: F_IN_ID, status: 0}).then(() => {
                        this.$message.info('已拒绝');
                    });

                });
            },
            // 批量官方推荐
            pilguanf_click: function (F_IN_ID) {
                this.$confirm('此操作将控制是否通过推荐, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlbplguanfStatus, {F_IN_ID: F_IN_ID, status: 1}).then(() => {
                        this.$message.success('成功推荐');
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlbplguanfStatus, {F_IN_ID: F_IN_ID, status: 0}).then(() => {
                        this.$message.info('已拒绝');
                    });

                });
            },
            // 批量上下架
            pilshangxj_click: function (F_IN_ID) {
                this.$confirm('此操作将控制是否通过上下架, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlbplshangxjStatus, {F_IN_ID: F_IN_ID,status: 1}).then(() => {
                        this.$message.success('上架成功');
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlbplshangxjStatus, {F_IN_ID: F_IN_ID, status: 0}).then(() => {
                        this.$message.info('下架成功');
                    });

                });
            },
            // 批量审核
            pilshenh_click: function (F_IN_ID) {
                this.$confirm('此操作将控制是否通过审核, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlbplVerifyChange, {F_IN_ID: F_IN_ID, status: 2}).then(() => {
                        this.$message.success('成功审核');
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlbplVerifyChange, {F_IN_ID: F_IN_ID,status:3}).then(() => {
                        this.$message.info('已拒绝');
                    });

                });
            },


            // 删除处理
            del: function (F_IN_ID) {
                let _this = this;
                if (this.$isEmpty(F_IN_ID)) {
                    return this.$message.error('请先选择删除的对象');
                }
                this.$post(this.$api.delTgBizlb, {F_IN_ID: F_IN_ID}).then(function (res) {
                    if (res.code == 200) {
                        _this.get_store_list();
                        return _this.$message.success("删除成功");
                    } else {
                        return _this.$message.error("删除失败！" + res.msg);
                    }
                });
            },
            // 指定ID修改状态
            rem_status: function (F_IN_ID) {
                this.$post(this.$api.tgbizlbremStatus, {F_IN_ID: F_IN_ID}).then(res => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                    } else {
                        this.$message.success('操作失败');
                    }
                    this.get_store_list();
                });
            },
            // 指定ID修改状态
            guanf_status: function (F_IN_ID) {
                this.$post(this.$api.tgbizlbguanfStatus, {F_IN_ID: F_IN_ID}).then(res => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                    } else {
                        this.$message.success('操作失败');
                    }
                    this.get_store_list();
                });
            },
            // 指定ID修改状态
            shangxj_status: function (F_IN_ID) {
                this.$post(this.$api.tgbizlbshangxjStatus, {F_IN_ID: F_IN_ID}).then(res => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                    } else {
                        this.$message.success('操作失败');
                    }
                    this.get_store_list();
                });
            },
            verify_click: function (id) {
                this.$confirm('此操作将控制是否通过审核, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlbVerifyChange, {id: id, shenhzt: 1}).then(() => {
                        this.$message.success('成功审核');
                        this.get_store_list();
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlbVerifyChange, {id: id, shenhzt: 2}).then(() => {
                        this.$message.info('已拒绝');
                        this.get_store_list();
                    });

                });
            },
        }
        ,
        created() {
            this.get_store_list();
        }
        ,
        mounted() {
        }
    }
    ;
</script>
<style lang="scss" scoped>
    .store_list_ul li {
        border-bottom: 1px solid #efefef;
        margin: 0 0 30px 0;
        padding-bottom: 15px;
        color: #666;

        h4 {
            margin-bottom: 15px;
            color: #333;
        }
    }
</style>
